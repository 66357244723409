import React from 'react';
import { FiTwitter, FiLinkedin, FiGithub } from 'react-icons/fi';


const SocialMedia = () => (
  <div className="app__social">
    <a href="https://twitter.com/HellloCode">
    <div>     
      <FiTwitter />
    </div>
  </a>
    <a href="https://www.linkedin.com/in/alexmedici/">
    <div>
      <FiLinkedin/>
     
    </div>
    </a>
    <a href="https://github.com/AlexanderMedici">
    <div>
      <FiGithub/>
    </div>
    </a>
  </div>
);

export default SocialMedia;